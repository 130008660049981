import { Link } from 'gatsby';
import styled from 'styled-components';
import { pixels, color, typography, breakpoint, width } from '../../../theme';

// style
export const Wrapper = styled.div`
  background-color: ${color.grey[90]};
  padding: 3rem 1.5rem; 
  border-top: ${props => props.smallprint ? `${pixels.one} solid ${color.grey[80]}` : null};

    @media ${breakpoint.md} {
      padding: ${props => props.smallprint ? '1rem 1.5rem' : '3rem 1.5rem'};
    }

    @media ${breakpoint.lg} {
      padding: ${props => props.smallprint ? '1rem 0' : '3rem 0'};
    }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media ${breakpoint.md} {
    flex-direction: row;
  }

  @media ${breakpoint.lg} {
    width: 80%;
    max-width: ${width.max}rem;
    margin: 0 auto;
  }
`;

export const LogoLink = styled(Link)`
  margin-bottom: 3rem;
  display: inherit;
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;

  @media ${breakpoint.md} {
    justify-content: flex-end;
    flex-direction: row;
    width: calc(100% / 3 * 2);
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${breakpoint.md} {
    width: calc(100% / 3);
    margin-bottom: 0;
  }

  a {
    font-size: ${typography.size.f6};
    text-decoration: none;
    color: ${color.white};
    padding: 0.5rem 0;
    width: fit-content;

    @media (hover:hover) {
      &:hover {
        color: ${color.grey[40]};
      }
    }
  }
`;

export const Title = styled.h5`
  color: ${color.grey[60]};
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${typography.size.f6};
  margin-bottom: 0.5rem;
  letter-spacing: ${typography.spacing.wide}em;
`;

export const SmallLinks = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${breakpoint.md} {
    flex-direction: row;
  }
`;

export const SmallLink = styled(Link)`
  color: ${color.grey[60]};
  margin-bottom: 1rem;

  @media ${breakpoint.md} {
    margin-right: 2rem;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Year = styled.span`
  white-space: nowrap;
  color: ${color.grey[60]};
`;
