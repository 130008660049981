import { Link } from 'gatsby';
import styled from 'styled-components';
import { transition, color, typography, breakpoint } from '../../../theme';

export const Wrapper = styled.div`
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 75vw;
  background-color: ${color.grey[90]};
  z-index: 200;
  transform: translateX(-100%);
  transition: transform ${transition.menu};
  overflow: scroll;

  &.open {
    transform: translateX(0);
    box-shadow: 4px 0 16px rgba(0,0,0,0.25);
  }

  @media ${breakpoint.md} {
    display: none;
  }
`;
export const Container = styled.nav`
  padding: 2rem;
  display: grid;
  gap: 2rem;
`;

export const LogoLink = styled(Link)`
  width: fit-content;
`;

export const Section = styled.div`
  /* display: grid; */
`;

export const Title = styled.h6`
  font-size: ${typography.size.f8};
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: ${typography.spacing.wide}em;
  color: ${color.grey[70]};
  margin-bottom: 0.5em;
`;

export const LinkGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  color: ${color.white};
  font-size: ${typography.size.f6};
  padding: 0.5rem 1rem;
  width: fit-content;
  font-weight: ${props => props.primary ? 'bold' : 'normal'};
`;
