import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title, image, imageAlt, keywords, homepage, category, schemaMarkup, type, canonical }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
            image
            imageAlt
          }
        }
      }
    `
  );

  // const metaDescription = description || site.siteMetadata.description;
  const metaImage = image ? `${site.siteMetadata.url}${image}` : `${site.siteMetadata.url}${site.siteMetadata.image}`;
  const metaImageAlt = imageAlt ? imageAlt : site.siteMetadata.imageAlt;
  const metaTitleTemplate = homepage ? '%s' : category ? '%s' : `%s – ${site.siteMetadata.title}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={metaTitleTemplate}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: 'og:image:alt',
          content: metaImageAlt,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:locale',
          content: 'en_GB',
        },
        {
          property: 'og:type',
          content: type,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:site',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
      ].concat(meta)}
    >
      {schemaMarkup &&
        <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
      }
      {canonical &&
        <link rel="canonical" href={canonical} />
      }
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: 'en-GB',
  meta: [],
  description: '',
  title: '',
  type: 'website'
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  keywords: PropTypes.string,
  type: PropTypes.string,
  homepage: PropTypes.bool,
  category: PropTypes.bool,
};

export default SEO;
