import React from 'react';
import styled from 'styled-components';
import { breakpoint, color, pixels, radius, shadow } from '../../theme';

const Container = styled.div`
  background-color: ${color.white};
  padding: 1.5rem;
  border-left: ${pixels.four} solid ${color.brand[50]};
  border-radius: ${radius.sm};
  box-shadow: ${shadow.box.card};

  @media ${breakpoint.md} {
    padding: 3rem;
  }
`;

const Card = ({children}) => {
  return (
    <Container>
      {children}
    </Container>
  );
};

export default Card;

