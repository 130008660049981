import styled from 'styled-components';
import { Link } from 'gatsby';
import { transition, pixels, color, typography, breakpoint, width, radius } from '../../../theme';

export const Wrapper = styled.header`
  padding: 1.5rem; 

  @media ${breakpoint.lg} {
    padding: 1.5rem 0;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 2.5rem;

  @media ${breakpoint.lg} {
    flex-direction: row;
    width: 80%;
    max-width: ${width.max}rem;
    margin: 0 auto;
    padding: 0;
  }
`;

export const LogoLink = styled(Link)`
  display: inherit;
`;

export const Nav = styled.nav`
  display: none;

  @media ${breakpoint.md} {
    display: flex;
  }
`;

export const PrimaryLinks = styled.div`
  font-size: ${typography.size.f5};
  line-height: ${typography.height.none};
  display: flex;
  align-items: center;
  gap: 3rem;
  
  & > a {
    color: ${color.grey[70]};
    text-decoration: none;
    padding: 0.5rem;
    transition: ${transition.hover};
    
    &:hover {
      color: ${color.grey[80]};
    }

  }
`;

export const BurgerIcon = styled.button`
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: ${transition.hover};

  &.open {
    height: 40px;
  }

  &:focus {
    outline: none;
  }

  &:before {
    transition: ${transition.hover};       
    display: block;
    height: ${pixels.two};
    width: 1.5rem;
    background: ${color.black};
    margin-bottom: ${pixels.eight};
    content: '';
  }

  &:after {
    display: block;
    height: 0.75rem;
    width: 1.5rem;
    border-top: ${pixels.two} solid ${color.black};
    border-bottom: ${pixels.two} solid ${color.black};
    content: '';
  }

  @media ${breakpoint.md} {
    display: none;
  }


  &.open:before {
      transform: rotate(-45deg);
      margin-bottom: -${pixels.two};
    }
  &.open:after {
      border: 0;
      height: ${pixels.two};
      width: 1.5rem;
      background: ${color.black};
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
`;

// This highlights the active link in the nav bar to indicate that you're
const activeClassName = 'active';
export const NavLink = styled(Link).attrs({ activeClassName })`
  &.${activeClassName} {
    color: ${color.brand[50]};
  }
`;

