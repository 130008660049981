// core
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import '../theme/base.css';

// components
import Head from '../components/core/Head';
import Header from '../components/core/Header';
import Footer from '../components/core/Footer';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.main`
  flex-grow: 1;
`;

// render
const Layout = ({ children }) => (
  <Wrapper>
    <Head />
    <Header />
    <Content>{children}</Content>
    <Footer />
  </Wrapper>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
