import React, { Component } from 'react';
import { color } from '../../theme';

export default class Icon extends Component {
  static defaultProps = {
    color: `${color.black}`,
    width: '1em',
    height: '100%'
  }

  render() {
    switch (this.props.name) {
      case 'close':
        return (
          <svg width={this.props.width} height={this.props.height} viewBox="0 0 48 48">
            <path fill={this.props.color} d="M47.44,3.83,44.17.56a1.94,1.94,0,0,0-2.73,0L25.36,16.64a1.93,1.93,0,0,1-2.72,0L6.56.56a1.94,1.94,0,0,0-2.73,0L.56,3.83a1.94,1.94,0,0,0,0,2.73L16.64,22.64a1.91,1.91,0,0,1,0,2.72L.56,41.44a1.94,1.94,0,0,0,0,2.73l3.27,3.27a1.94,1.94,0,0,0,2.73,0L22.64,31.36a1.91,1.91,0,0,1,2.72,0L41.44,47.44a1.94,1.94,0,0,0,2.73,0l3.27-3.27a1.94,1.94,0,0,0,0-2.73L31.36,25.36a1.93,1.93,0,0,1,0-2.72L47.44,6.56A1.94,1.94,0,0,0,47.44,3.83Z" />
          </svg>
        );
      case 'arrow':
        return (
          <svg width={this.props.width} height={this.props.height} viewBox="0 0 48 64">
            <path fill={this.props.color} d="M28.24,43.6V2a1.94,1.94,0,0,0-1.93-2H21.69a1.94,1.94,0,0,0-1.93,2V43.6A1.92,1.92,0,0,1,16.47,45L6.56,34.92a1.9,1.9,0,0,0-2.73,0L.56,38.24A2,2,0,0,0,.56,41L22.64,63.43a1.91,1.91,0,0,0,2.72,0L47.44,41a2,2,0,0,0,0-2.77l-3.27-3.32a1.9,1.9,0,0,0-2.73,0L31.53,45A1.92,1.92,0,0,1,28.24,43.6Z" />
          </svg>
        );
      case 'popup':
        return (
          <svg width={this.props.width} height={this.props.height} viewBox="0 0 14 14">
            <path fill={this.props.color} d="M12.25 1.75L13.25 1.75L13.25 0.75L12.25 0.75L12.25 1.75ZM5.70956 6.87623C5.31903 7.26675 5.31903 7.89992 5.70956 8.29044C6.10008 8.68096 6.73325 8.68096 7.12377 8.29044L5.70956 6.87623ZM7.58333 2.75L12.25 2.75L12.25 0.75L7.58333 0.75L7.58333 2.75ZM11.25 1.75L11.25 6.41667L13.25 6.41667L13.25 1.75L11.25 1.75ZM11.5429 1.04289L5.70956 6.87623L7.12377 8.29044L12.9571 2.45711L11.5429 1.04289Z" />
            <path fill="none" stroke={this.props.color} strokeWidth="2" strokeLinecap="round" d="M5.24984 2.33325V2.33325C3.63901 2.33325 2.33317 3.63909 2.33317 5.24992L2.33317 6.99992C2.33317 8.55442 2.33317 9.33167 2.61425 9.93444C2.91232 10.5737 3.42609 11.0874 4.06532 11.3855C4.66809 11.6666 5.44534 11.6666 6.99984 11.6666L8.74984 11.6666C10.3607 11.6666 11.6665 10.3607 11.6665 8.74992V8.74992" />
          </svg>
        );
      case 'tick':
        return (
          <svg width={this.props.width} height={this.props.height} viewBox="0 0 24 24">
            <path fill="none" stroke={this.props.color} strokeWidth="2" strokeLinecap="round" d="M6.36 14.5477L8.86693 16.4279C9.29563 16.7494 9.90156 16.6759 10.2409 16.2611L17 8" />
          </svg>
        );
      default:
    }
  }
}
