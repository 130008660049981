import React, { Component } from 'react';
import { color } from '../../theme';

export default class Logo extends Component {
  static defaultProps = {
    color: `${color.grey[90]}`,
    width: '10em',
    height: '100%',
  };

  render() {
    switch (this.props.name) {
      case 'knots':
        return (
          <svg
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 1776 400"
          >
            <path fill={this.props.color} d="M299.44,375.69l-158.07-199a7,7,0,0,1,.56-9.38L289,22.13a9.67,9.67,0,0,0,2.19-10.74,9.68,9.68,0,0,0-9.11-6.11h-1.69a9.77,9.77,0,0,0-7,2.88l-240,239.59a6.81,6.81,0,0,1-4.91,2.08,7,7,0,0,1-7.09-7.05V15.13a9.86,9.86,0,0,0-9.85-9.85H9.65A9.86,9.86,0,0,0-.2,15.13V381.82a9.85,9.85,0,0,0,9.85,9.84h1.88a9.85,9.85,0,0,0,9.85-9.84V289.17a7.09,7.09,0,0,1,2.08-5L116.68,192a7,7,0,0,1,10.47.64L281.47,387.92a9.79,9.79,0,0,0,7.73,3.74h2.53a9.84,9.84,0,0,0,7.71-16Z"/>
            <path fill={this.props.color} d="M686.19,5.28h-1.41a9.86,9.86,0,0,0-9.85,9.85V334.37a7,7,0,0,1-7.11,7.05,6.83,6.83,0,0,1-5.42-2.67L399.15,9a9.82,9.82,0,0,0-7.7-3.7H387a9.86,9.86,0,0,0-9.85,9.85V381.82a9.85,9.85,0,0,0,9.85,9.84h1.41a9.85,9.85,0,0,0,9.85-9.84v-322a6.81,6.81,0,0,1,2.19-5.14,7.34,7.34,0,0,1,4.93-1.92,6.85,6.85,0,0,1,5.41,2.67L676.18,388a9.82,9.82,0,0,0,7.7,3.7h2.31a9.85,9.85,0,0,0,9.85-9.84V15.13A9.86,9.86,0,0,0,686.19,5.28Z"/>
            <path fill={this.props.color} d="M1452.57,5.28H1177.34a9.85,9.85,0,1,0,0,19.69h119.52a7,7,0,0,1,7,7V381.82a9.85,9.85,0,0,0,9.85,9.84h1.88a9.85,9.85,0,0,0,9.85-9.84V32a7,7,0,0,1,7-7h120.07a9.85,9.85,0,1,0,0-19.69Z"/>
            <path fill={this.props.color} d="M1768.83,255.08c-4.64-10.76-12.25-20.52-22.61-29s-24-16-40.71-22.41-37.14-12-60.85-16.78c-23.23-4.71-42.72-9.94-57.93-15.55s-27.29-12-36.1-19-15-14.76-18.5-23.26a75.09,75.09,0,0,1-5.38-29v-1.1A65.06,65.06,0,0,1,1534,69.18a79.55,79.55,0,0,1,20.73-25.3c9-7.35,20-13.26,32.71-17.56a132.77,132.77,0,0,1,42.55-6.5A186.51,186.51,0,0,1,1689,29.18a193,193,0,0,1,53.56,28.09,9.86,9.86,0,0,0,13.61-1.89l1.08-1.39a9.86,9.86,0,0,0-2-14.06,220.35,220.35,0,0,0-52.46-28C1681.53,4.1,1657.41.12,1631.07.12c-18.33,0-35.34,2.61-50.57,7.75a122.59,122.59,0,0,0-39.62,21.61,104.52,104.52,0,0,0-26.16,32.21,83.88,83.88,0,0,0-9.54,39v1.1c0,14,2.44,26.65,7.25,37.54s12.61,20.7,23.18,29,24.26,15.62,41,21.85,36.87,11.75,60.27,16.5c22.83,4.7,42.14,9.94,57.4,15.55s27.29,11.88,36.1,18.7,14.94,14.5,18.49,23,5.38,18.51,5.38,29.59v1.09a72.55,72.55,0,0,1-7.54,32.79,78.4,78.4,0,0,1-21.84,26.37,105.63,105.63,0,0,1-34.4,17.57c-13.33,4.14-28.33,6.24-44.6,6.24-27.86,0-53.44-4.85-76-14.41-20.38-8.61-40.08-21.3-58.56-37.71a9.82,9.82,0,0,0-13.77.71l-1.08,1.17a9.86,9.86,0,0,0,.85,14.16,239.49,239.49,0,0,0,64.36,40.11c25.13,10.39,53.12,15.66,83.18,15.66,19.82,0,38-2.6,54-7.74s29.85-12.51,41.11-21.89a103.31,103.31,0,0,0,26.44-33.05,92.29,92.29,0,0,0,9.48-41.73v-1.09C1775.8,278.15,1773.45,265.8,1768.83,255.08Z"/>
            <path fill={this.props.color} d="M1141.31,126.52A196.85,196.85,0,0,0,959.8,6.24a9.38,9.38,0,1,0,0,18.75,178.19,178.19,0,1,1,0,356.37,139.8,139.8,0,0,1-99.49-41.2c-1.28-1.28-2.52-2.61-3.75-3.93a206.6,206.6,0,0,0,26.89,12.52,214,214,0,0,0,76.35,13.86,159.43,159.43,0,1,0,0-318.86,9.38,9.38,0,1,0,0,18.75,140.68,140.68,0,1,1,0,281.35,195.5,195.5,0,0,1-69.69-12.63,180,180,0,0,1-56.69-34.34c-1.48-1.33-2.93-2.69-4.35-4.06a140.56,140.56,0,0,1-10-52.09A159.31,159.31,0,0,0,959.8,325.1a121.92,121.92,0,1,0,0-243.84,9.38,9.38,0,1,0,0,18.76,103.16,103.16,0,1,1,0,206.32,140.76,140.76,0,0,1-135.64-103.2,139.62,139.62,0,0,1,22.68-46.33,140.92,140.92,0,0,1,70.43-50.25,9.38,9.38,0,0,0-5.66-17.89,159.93,159.93,0,0,0-92.51,77,140.49,140.49,0,0,1,35.17-93A9.38,9.38,0,1,0,840.2,60.21a159.51,159.51,0,0,0-35.42,142.86,160.94,160.94,0,0,0-3.56,54.35,144.91,144.91,0,0,1-19.64-72.76,9.38,9.38,0,0,0-18.76,0,162.4,162.4,0,0,0,15.63,69.61,176,176,0,0,0,34.79,49.3A159.29,159.29,0,0,0,959.8,400.12a197,197,0,0,0,181.51-273.6Z"/>
          </svg>
        );
      default:
    }
  }
}
