import React, { Component } from 'react';
import { Logo } from '../../general';
import { color } from '../../../theme';
import { Link } from 'gatsby';

import {
  Wrapper,
  Nav,
  Section,
  Title,
  SmallLinks,
  SmallLink,
  Year,
  Container,
  LogoLink,
} from './style';

// render
export default class Footer extends Component {
  render() {
    return (
      <footer>
        <Wrapper>
          <Container>
            <LogoLink to="/" title="Go to homepage" aria-label="Homepage">
              <Logo name="knots" color={`${color.white}`} aria-hidden="true" />
            </LogoLink>
            <Nav>
              <Section>
                <Title>Specialities</Title>
                <Link to="/sports-massage/">Sports Massage</Link>
                <Link to="/holistic-massage/">Holistic Massage</Link>
              </Section>
              <Section>
                <Title>Explore</Title>
                <Link to="/reviews/">Reviews</Link>
                <Link to="/faqs/">FAQs</Link>
                <Link to="/contact/">Contact</Link>
                {/* <Link to="/blog/">Blog</Link> */}
              </Section>
            </Nav>
          </Container>
        </Wrapper>
        <Wrapper smallprint>
          <Container>
            <SmallLinks>
              <SmallLink to="/terms-and-conditions/">Terms & Conditions</SmallLink>
              <SmallLink to="/privacy-policy/">Privacy Policy</SmallLink>
            </SmallLinks>
            <Year>&copy; {new Date().getFullYear()}</Year>
          </Container>
        </Wrapper>
      </footer>
    );
  }
}
