// Mono
export const white = '#FFFFFF';
export const black = '#292727';

// Mono
export const grey = {
  0: '#ffffff',
  10: '#f4f4f3',
  15: '#e9e8e8',
  20: '#dedddd',
  25: '#d4d2d1',
  30: '#bebcbb',
  40: '#a8a6a5',
  50: '#93908f',
  60: '#7d7a7a',
  70: '#676564',
  80: '#52504f',
  90: '#3c3b3a',
  100: '#262625'
};

// Brand
export const brand = {
  0: '#ffffff',
  10: '#ffebe1',
  20: '#ffd7c7',
  30: '#ffc1ab',
  40: '#ffaa91',
  50: '#DD8C72', // new color
  60: '#ee7b5f',
  70: '#c66f5c',
  80: '#985c52',
  90: '#66423f',
  100: '#332424'
};

// Purple
export const purple = {
  0: '#ffffff',
  10: '#f1f0fa',
  20: '#e3e0f5',
  30: '#d5d0f0',
  40: '#c6bfeb',
  50: '#b4ace4',
  60: '#a196dd',
  70: '#8d82c2',
  80: '#716a94',
  90: '#4e4a62',
  100: '#292831'
};

// Green
export const green = {
  0: '#ffffff',
  10: '#e2f6ed',
  20: '#c6eddb',
  30: '#a7e2c7',
  40: '#8ad7b4',
  50: '#6dc99f',
  60: '#53b789',
  70: '#529c7b',
  80: '#4b7c65',
  90: '#395448',
  100: '#202b26'
};

// Other
export const special = {
  blueLight: '#cedce0',
  blueMid: '#819ba3',
  greenLight: '#BCF0C8',
  greenMid: '#74c795',
  greenDark: '#4A715A',
  reviewGreenMain: '#00b67a',
  reviewGreenLight: '#7dd2b6',

};
