import React from 'react';
import styled from 'styled-components';
import { breakpoint, color } from '../../../theme';

const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: ${color.black};
  opacity: 0.5;
  z-index: 100;
  cursor: pointer;

  @media ${breakpoint.md} {
    display: none;
  }
`;

const Backdrop = props => <Background onClick={props.click} />;

export default Backdrop;
