import React from 'react';
import { Wrapper, Container, LogoLink, NavLink, Section, Title, LinkGroup } from './style';
import { color } from '../../../theme';
import { Logo } from '../../general';

const MobileNav = props => {
  let mobileNavClasses = '';
  if (props.show) {
    mobileNavClasses = 'open';
  }

  //render
  return (
    <Wrapper className={mobileNavClasses}>
      <Container>
        <LogoLink to="/" title="Go to homepage" aria-label="Homepage">
          <Logo name="knots" width="8em" color={`${color.white}`} aria-hidden="true" />
        </LogoLink>
        <Section>
          <Title>Specialities</Title>
          <LinkGroup>
            <NavLink to="/sports-massage/" title="Reviews">Sports Massage</NavLink>
            <NavLink to="/holistic-massage/" title="Reviews">Holistic Massage</NavLink>
          </LinkGroup>
        </Section>
        <Section>
          <Title>Explore</Title>
          <LinkGroup>
            <NavLink to="/reviews/" title="Reviews">Reviews</NavLink>
            <NavLink to="/faqs/" title="FAQs">FAQs</NavLink>
            <NavLink to="/contact/" title="Contact">Contact</NavLink>
            {/* <NavLink to="/blog/" title="Blog">Blog</NavLink> */}
          </LinkGroup>
        </Section>
        <Section>
          <Title>Help</Title>
          <LinkGroup>
            <NavLink to="/privacy-policy/" title="Privacy Policy">Privacy Policy</NavLink>
            <NavLink to="/terms-and-conditions/" title="Terms and Conditions">Terms and Conditions</NavLink>
          </LinkGroup>
        </Section>
      </Container>
    </Wrapper>
  );
};

export default MobileNav;
