import React, { Component } from 'react';
import styled from 'styled-components';
import Portal from '../../utils/Portal';
import Icon from './Icon';
import { shadow, radius, color, breakpoint, width } from '../../theme';

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const ModalCard = styled.div`
  position: relative;
  min-width: 90vw;
  max-width: 90vw;
  max-height: 90vh;
  overflow: scroll;
  z-index: 10;
  background-color: ${props => props.backgroundColor || `${color.white}`};
  width: ${props => props.width || undefined };
  border-radius: ${radius.md};
  box-shadow: ${shadow.box.modal};
  padding: 2rem;

  @media ${breakpoint.md} {
    min-width: 70vw;
    padding: 3rem;
  }
  @media ${breakpoint.lg} {
    min-width: 60vw;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${color.black};
  opacity: 0.5;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: transparent;
  padding: 1rem;
  cursor: pointer;
  display: flex;
`;

export default class Modal extends Component {
  render() {
    const { children, toggle, on, backgroundColor, width } = this.props;
    return (
      <Portal>
        {on && (
          <ModalWrapper>
            <ModalCard backgroundColor={backgroundColor} width={width}>
              <CloseButton onClick={toggle}>
                <Icon name="close" />
              </CloseButton>
              {children}
            </ModalCard>
            <Background onClick={toggle} />
          </ModalWrapper>
        )}
      </Portal>
    );
  }
}
