import React, { Component } from 'react';
import { Wrapper, Container, Nav, PrimaryLinks, NavLink, BurgerIcon, LogoLink } from './style';
import MobileNav from '../MobileNav';
import Backdrop from '../MobileNav/Backdrop';
import { Logo } from '../../general';

export default class Header extends Component {

  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }

    let menuIconOpen = '';
    if (this.state.sideDrawerOpen) {
      menuIconOpen = 'open';
    }

    return (
      <Wrapper>
        <Container>
          <LogoLink to="/" title="Go to homepage" aria-label="Homepage">
            <Logo name="knots" aria-hidden="true" />
          </LogoLink>
          <Nav>
            <PrimaryLinks>
              <NavLink to="/contact/">Contact</NavLink>
            </PrimaryLinks>
          </Nav>
          <BurgerIcon onClick={this.drawerToggleClickHandler} className={menuIconOpen} aria-label="Main menu" />
          <MobileNav show={this.state.sideDrawerOpen} />
          {backdrop}
        </Container>
      </Wrapper>
    );
  }
}
